import { getLoggedUser } from "@/communication/user";
import { UserStatus } from "@/global/dtos/user.dto";
import useUserStore from "@/store/user";
import { getAuthState, returnToLogin } from "./auth";

const initFetch = async (): Promise<void> => {
    const state = getAuthState();
    if (state) {
        const userRes = await getLoggedUser();
        useUserStore.setState({
            id: userRes?.data.id,
            displayName: userRes?.data.displayName,
            firstName: userRes?.data.firstName,
            lastName: userRes?.data.lastName,
            email: userRes?.data.email,
            instaHandle: userRes?.data.instaHandle,
            tiktokHandle: userRes?.data.tiktokHandle,
            bio: userRes?.data.bio,
            role: userRes?.data.role,
            status: userRes?.data.status,
            address: userRes?.data.address,
            phone: userRes?.data.phone,
            ssn: userRes?.data.ssn,
            city: userRes?.data.city,
            postalCode: userRes?.data.postalCode,
            dob: userRes?.data.dob,
            state: userRes?.data.state,
        }); 
    } else {
        returnToLogin();
    }
};

export default initFetch;