import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  StyledActionButtons,
  StyledDisplayName,
  StyledForm,
  StyledHandleContainer,
  StyledHandleDataContainer,
  StyledInputsContainer,
  StyledInputsRowContainer,
  StyledInstagramField,
  StyledPageContainer,
  StyledPageHeader,
  StyledProfilePictureContainer,
  StyledTabs,
  StyledTabsContent,
  StyledTabsHeader,
  StyledTosContainer,
} from "./style";
import { ReactComponent as ProfileIcon } from "@/assets/icons/profileIcon.svg";
import PageTitle from "@/components/pageTitle";
import InputField from "@/components/inputs/inputField";
import { useTouchField } from "@/hooks/useTouchField";
import Button from "@/components/buttons";
import useUserStore from "@/store/user";
import useMinLength from "@/hooks/validation/useMinLength";
import useIsEmail from "@/hooks/validation/useIsEmail";
import {
  deleteProfilePicture,
  getBankDetails,
  getProfilePicture,
  updateBankDetails,
  updateSelf,
  uploadProfilePicture,
} from "@/communication/user";
import PictureUpload from "@/components/pictureUpload";
import { ChangeEvent } from "react";
import { FileWithPreview } from "@/types/product";
import useLoading from "@/hooks/useLoading";
import SwitchField from "@/components/inputs/switch";
import SelectField from "@/components/select";
import { getAllAgencies } from "@/communication/agencies";
import { AgencyDto } from "@/global/dtos/agency.dto";
import { UserStatus } from "@/global/dtos/user.dto";
import useIsValidDob from "@/hooks/validation/useIsValidDob";

const MyProfile: FunctionComponent = () => {
  const {
    id,
    displayName,
    firstName,
    lastName,
    email,
    instaHandle,
    tiktokHandle,
    bio,
    agencyId,
    address,
    phone,
    ssn,
    dob,
    city,
    state,
    postalCode,
    status,
  } = useUserStore();

  type UserInfoFormValues = {
    displayName: string;
    email: string;
    oldPassword?: string;
    newPassword?: string;
    firstName: string;
    lastName: string;
    bio: string;
    profilePicture?: string;
    address?: string;
    phone?: string;
    ssn?: string;
    dob?: string;
    city?: string;
    state?: string;
    postalCode?: string;
  };

  const [userInfoFormValues, setUserInfoFormValues] =
    useState<UserInfoFormValues>({
      displayName: displayName || "",
      email: email || "",
      oldPassword: "",
      newPassword: "",
      firstName: firstName || "",
      lastName: lastName || "",
      bio: bio || "",
      address: undefined,
      phone: undefined,
      ssn: undefined,
      dob: undefined,
      city: undefined,
      state: undefined,
      postalCode: undefined,
    });

  type BankInfoFormValues = {
    email: string;
    firstName: string;
    lastName: string;
    useAgency: boolean;
    agencyName?: string;
    address1?: string;
    address2?: string;
    phone?: string;
    ssn?: string;
    dob?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    bankAccount?: string;
    routingNumber?: string;
    newBankAccount?: string;
    newRoutingNumber?: string;
  };

  const [bankInfoFormValues, setBankInfoFormValues] =
    useState<BankInfoFormValues>({
      email: email || "",
      firstName: firstName || "",
      lastName: lastName || "",
      useAgency: false,
      address1: undefined,
      address2: undefined,
      phone: undefined,
      ssn: undefined,
      dob: undefined,
      city: undefined,
      state: undefined,
      postalCode: undefined,
      bankAccount: undefined,
      routingNumber: undefined,
      newBankAccount: undefined,
      newRoutingNumber: undefined,
    });

  const [activeTab, setActiveTab] = useState(1);
  const { showLoading, hideLoading } = useLoading();
  const [agencyList, setAgencyList] = useState<AgencyDto[] | null>(null);
  const [tosAcceptance, setTosAcceptance] = useState(false);

  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const bankInfoTab = urlParams.get("bank-info");

  const tabClick = (change: number): void => {
    setActiveTab(change);
  };

  useEffect(() => {
    if (bankInfoTab) {
      setActiveTab(2);
    }
  }, [bankInfoTab]);

  useEffect(() => {
    if (displayName)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["displayName"]: displayName,
      }));
    if (email)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["email"]: email,
      }));
    if (firstName)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["firstName"]: firstName,
      }));
    if (lastName)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["lastName"]: lastName,
      }));
    if (bio)
      setUserInfoFormValues((prevValues) => ({ ...prevValues, ["bio"]: bio }));
    if (address)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["address"]: address,
      }));
    if (phone)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["phone"]: phone,
      }));
    if (ssn)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["ssn"]: ssn,
      }));
    if (dob)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["dob"]: dob,
      }));
    if (city)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["city"]: city,
      }));
    if (state)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["state"]: state,
      }));
    if (postalCode)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["postalCode"]: postalCode,
      }));
  }, [
    displayName,
    email,
    firstName,
    lastName,
    bio,
    address,
    phone,
    ssn,
    dob,
    city,
    state,
    postalCode,
  ]);

  useEffect(() => {
    showLoading();
    getProfilePicture(id)
      .then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProfilePicture(pictures);
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getAllAgencies().then((res: any) => {
      setAgencyList && setAgencyList(res.data);
    });
  }, []);

  useEffect(() => {
    showLoading();
    getBankDetails()
      .then((res: any) => {
        if (res.data) {
          if (res.data?.useAgency) {
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["useAgency"]: true,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["agencyName"]: res.data?.agencyName,
            }));
          } else {
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["firstName"]: res.data.firstName,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["lastName"]: res.data.lastName,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["phone"]: res.data.phone,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["email"]: res.data.email,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["dob"]: res.data.dob,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["address1"]: res.data.address1,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["city"]: res.data.city,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["state"]: res.data.state,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["postalCode"]: res.data.postalCode,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["ssn"]: res.data.ssn,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["bankAccount"]: `*********${res.data.bankAccount}`,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["routingNumber"]: res.data.routingNumber,
            }));
          }
        }
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  }, []);

  const isFormValid = true;

  const emailErrorMessage = useIsEmail(userInfoFormValues.email);
  const passwordErrorMessage = useMinLength(userInfoFormValues.newPassword, 8);
  const dobErrorMessage = useIsValidDob(bankInfoFormValues.dob!);

  const {
    isFieldTouched: isUserFieldTouched,
    handleFieldTouch: handleUserFieldTouch,
    touchedFields: touchedUserFields,
  } = useTouchField<UserInfoFormValues>();

  const {
    isFieldTouched: isBankFieldTouched,
    handleFieldTouch: handleBankFieldTouch,
    touchedFields: touchedBankFields,
  } = useTouchField<BankInfoFormValues>();

  const handleChange = (
    fieldName: keyof (UserInfoFormValues & BankInfoFormValues),
    value: string
  ): void => {
    if (fieldName in userInfoFormValues && activeTab !== 2) {
      handleUserFieldTouch(fieldName as keyof UserInfoFormValues);
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));
    }
    if (fieldName in bankInfoFormValues && activeTab === 2) {
      handleBankFieldTouch(fieldName as keyof BankInfoFormValues);
      setBankInfoFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));
    }
  };

  const handleUpdate = (): void => {
    showLoading();
    saveProfilePicture();

    updateSelf(userInfoFormValues)
      .then(async (res: any) => {
        hideLoading();
        toast.success("Profile updated!", {
          position: "bottom-center",
          autoClose: 1500,
        });

        if (displayName)
          setUserInfoFormValues((prevValues) => ({
            ...prevValues,
            ["displayName"]: res.data.displayName,
          }));
        if (email)
          setUserInfoFormValues((prevValues) => ({
            ...prevValues,
            ["email"]: res.data.email,
          }));
        if (firstName)
          setUserInfoFormValues((prevValues) => ({
            ...prevValues,
            ["firstName"]: res.data.firstName,
          }));
        if (lastName)
          setUserInfoFormValues((prevValues) => ({
            ...prevValues,
            ["lastName"]: res.data.lastName,
          }));
        if (bio)
          setUserInfoFormValues((prevValues) => ({
            ...prevValues,
            ["bio"]: res.data.bio,
          }));
        setUserInfoFormValues((prevValues) => ({
          ...prevValues,
          ["oldPassword"]: undefined,
        }));
        setUserInfoFormValues((prevValues) => ({
          ...prevValues,
          ["newPassword"]: undefined,
        }));
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  const [profilePicture, setProfilePicture] = useState<FileWithPreview[]>([]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files).map((file) => {
        const preview = URL.createObjectURL(file);
        return Object.assign(file, { preview });
      });
      setProfilePicture(filesArray);
    }
  };

  const saveProfilePicture = () => {
    const formData = new FormData();
    profilePicture.forEach((file) => {
      formData.append("files", file);
    });
    uploadProfilePicture(formData)
      .then(() => {})
      .catch((err) => {});
  };

  const handleRemoveFile = (file: FileWithPreview) => {
    setProfilePicture((prevFiles) => prevFiles.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview);
    if (file.id) {
      deleteProfilePicture(file.id)
        .then((res: any) => {})
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const updateBankInfo = (): void => {
    if (!tosAcceptance && status === UserStatus.INCOMPLETE) {
      toast.error("You must accept terms of service first", {
        position: "bottom-center",
        autoClose: 1500,
      });
    } else {
      if (bankInfoFormValues.useAgency) {
        showLoading();
        updateBankDetails(bankInfoFormValues.agencyName)
          .then(async (res: any) => {
            toast.success("Bank info updated!", {
              position: "bottom-center",
              autoClose: 1500,
            });
            hideLoading();
          })
          .catch((error: any) => {
            hideLoading();
            toast.error("Something went wrong!", {
              position: "bottom-center",
              autoClose: 1500,
            });
            console.log(error);
          });
      } else {
        showLoading();
        updateBankDetails(undefined, bankInfoFormValues)
          .then(async (res: any) => {
            toast.success("Bank info updated!", {
              position: "bottom-center",
              autoClose: 1500,
            });
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["firstName"]: res.data.firstName,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["lastName"]: res.data.lastName,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["phone"]: res.data.phone,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["email"]: res.data.email,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["dob"]: res.data.dob,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["address1"]: res.data.address1,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["city"]: res.data.city,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["state"]: res.data.state,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["postalCode"]: res.data.postalCode,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["ssn"]: res.data.ssn,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["bankAccount"]: `*********${res.data.bankAccount}`,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["routingNumber"]: res.data.routingNumber,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["newBankAccount"]: undefined,
            }));
            setBankInfoFormValues((prevValues) => ({
              ...prevValues,
              ["newRoutingNumber"]: undefined,
            }));
            hideLoading();
          })
          .catch((error: any) => {
            hideLoading();
            toast.error("Something went wrong!", {
              position: "bottom-center",
              autoClose: 1500,
            });
            console.log(error);
          });
      }
    }
  };

  return (
    <div className="page">
      <StyledPageHeader>
        <PageTitle title="Profile page" icon={<ProfileIcon />} />
      </StyledPageHeader>
      <StyledPageContainer>
        <StyledTabsHeader>
          <StyledTabs
            onClick={() => {
              tabClick(1);
            }}
            className={`${activeTab === 1 ? "active" : ""}`}
          >
            Personal information
          </StyledTabs>
          <StyledTabs
            onClick={() => {
              tabClick(0);
            }}
            className={`${activeTab === 0 ? "active" : ""}`}
          >
            Storefront settings
          </StyledTabs>
          <StyledTabs
            onClick={() => {
              tabClick(2);
            }}
            className={`${activeTab === 2 ? "active" : ""}`}
          >
            Bank information
          </StyledTabs>
        </StyledTabsHeader>
        <StyledTabsContent
          className={`tab-content ${activeTab === 1 ? "activeTab" : ""}`}
        >
          <StyledForm>
            <StyledInputsContainer>
              <StyledInputsRowContainer>
                <InputField
                  type="text"
                  label="Email"
                  value={userInfoFormValues.email}
                  errorMessage={
                    isUserFieldTouched("email") ? emailErrorMessage : ""
                  }
                  onChange={(e) =>
                    handleChange("email", e.target.value.toLowerCase())
                  }
                  placeholder="Email"
                />
                {/* <InputField
                  type="text"
                  label="Password"
                  value={formValues.password}
                  errorMessage={
                    isFieldTouched("password") ? passwordErrorMessage : ""
                  }
                  onChange={(e) => handleChange("password", e.target.value)}
                  placeholder="Password"
                  disabled={true}
                /> */}
              </StyledInputsRowContainer>
              <StyledInputsRowContainer>
                <InputField
                  type="text"
                  label="Old password"
                  value={userInfoFormValues.oldPassword}
                  errorMessage={
                    isUserFieldTouched("oldPassword")
                      ? passwordErrorMessage
                      : ""
                  }
                  onChange={(e) => handleChange("oldPassword", e.target.value)}
                  placeholder="Old Password"
                />
                <InputField
                  type="text"
                  label="New password"
                  value={userInfoFormValues.newPassword}
                  errorMessage={
                    isUserFieldTouched("newPassword")
                      ? passwordErrorMessage
                      : ""
                  }
                  onChange={(e) => handleChange("newPassword", e.target.value)}
                  placeholder="New Password"
                />
              </StyledInputsRowContainer>
              <StyledInputsRowContainer>
                <InputField
                  type="text"
                  label="First name"
                  value={userInfoFormValues.firstName}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  placeholder="First name"
                />
                <InputField
                  type="text"
                  label="Last Name"
                  value={userInfoFormValues.lastName}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  placeholder="Last name"
                />
              </StyledInputsRowContainer>
              <StyledActionButtons>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="outlined"
                  onClick={handleUpdate}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="solid"
                  onClick={handleUpdate}
                >
                  SAVE
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledTabsContent>

        <StyledTabsContent
          className={`tab-content ${activeTab === 0 ? "activeTab" : ""}`}
        >
          <StyledForm>
            <StyledHandleContainer>
              <StyledProfilePictureContainer>
                <PictureUpload
                  multiple={false}
                  thumbnails={false}
                  onRemove={handleRemoveFile}
                  onFileChange={handleFileChange}
                  pictureList={profilePicture}
                  showActions={true}
                  profilePic={true}
                />
              </StyledProfilePictureContainer>
              <StyledHandleDataContainer>
                <StyledInstagramField>
                  <span>Instagram HANDLE</span>
                  <span style={{ fontWeight: "bold" }}>
                    {instaHandle ?? "/"}
                  </span>
                </StyledInstagramField>
                <StyledInstagramField>
                  <span>TikTok HANDLE</span>
                  <span style={{ fontWeight: "bold" }}>
                    {tiktokHandle ?? "/"}
                  </span>
                </StyledInstagramField>
                <StyledDisplayName>
                  <InputField
                    type="text"
                    label="Display Name"
                    value={userInfoFormValues.displayName}
                    onChange={(e) =>
                      handleChange("displayName", e.target.value)
                    }
                    placeholder="Display name"
                    disabled={true}
                  />
                </StyledDisplayName>
              </StyledHandleDataContainer>
            </StyledHandleContainer>
            <StyledInputsContainer>
              <InputField
                type="textarea"
                label="About me"
                value={userInfoFormValues.bio}
                onChange={(e) => handleChange("bio", e.target.value)}
                placeholder="About me"
              />

              <StyledActionButtons>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="outlined"
                  onClick={handleUpdate}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="solid"
                  onClick={handleUpdate}
                >
                  SAVE
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledTabsContent>

        <StyledTabsContent
          className={`tab-content ${activeTab === 2 ? "activeTab" : ""}`}
        >
          <StyledForm>
            <StyledInputsContainer>
              <SwitchField
                onChange={(value: boolean) => {
                  setBankInfoFormValues((prevValues) => ({
                    ...prevValues,
                    ["useAgency"]: value,
                  }));
                }}
                defaultChecked={bankInfoFormValues.useAgency || false}
                label="Using an Agency"
                disabled={true}
              />
              {bankInfoFormValues.useAgency ? (
                <SelectField
                  label="Agency"
                  options={agencyList?.map((agency) => {
                    return { value: agency.name, label: agency.name };
                  })}
                  onChange={(e) => handleChange("agencyName", e.value)}
                  placeholder={bankInfoFormValues.agencyName}
                  isDisabled={true}
                />
              ) : (
                <>
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="First name"
                      value={bankInfoFormValues.firstName}
                      onChange={(e) =>
                        handleChange("firstName", e.target.value)
                      }
                      placeholder="First name"
                    />
                    <InputField
                      type="text"
                      label="Last Name"
                      value={bankInfoFormValues.lastName}
                      onChange={(e) => handleChange("lastName", e.target.value)}
                      placeholder="Last name"
                    />
                  </StyledInputsRowContainer>
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="Phone"
                      value={bankInfoFormValues.phone}
                      onChange={(e) => handleChange("phone", e.target.value)}
                      placeholder="Phone"
                    />
                    <InputField
                      type="text"
                      label="Email"
                      value={bankInfoFormValues.email}
                      errorMessage={
                        isBankFieldTouched("email") ? emailErrorMessage : ""
                      }
                      onChange={(e) =>
                        handleChange("email", e.target.value.toLowerCase())
                      }
                      placeholder="Email"
                    />
                  </StyledInputsRowContainer>
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="Date of birth month/day/year"
                      value={bankInfoFormValues.dob}
                      onChange={(e) => handleChange("dob", e.target.value)}
                      placeholder="03/27/1980"
                      errorMessage={
                        isBankFieldTouched("dob") ? dobErrorMessage : ""
                      }
                    />
                    <InputField
                      type="text"
                      label="Address"
                      value={bankInfoFormValues.address1}
                      onChange={(e) => handleChange("address1", e.target.value)}
                      placeholder="Address"
                    />
                  </StyledInputsRowContainer>
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="City"
                      value={bankInfoFormValues.city}
                      onChange={(e) => handleChange("city", e.target.value)}
                      placeholder="City"
                    />
                    <InputField
                      type="text"
                      label="State"
                      value={bankInfoFormValues.state}
                      onChange={(e) => handleChange("state", e.target.value)}
                      placeholder="State"
                    />
                  </StyledInputsRowContainer>
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="Postal code"
                      value={bankInfoFormValues.postalCode}
                      onChange={(e) =>
                        handleChange("postalCode", e.target.value)
                      }
                      placeholder="Postal code"
                    />
                    <InputField
                      type="text"
                      label="Social Security Number"
                      value={bankInfoFormValues.ssn}
                      onChange={(e) => handleChange("ssn", e.target.value)}
                      placeholder="000000000"
                    />
                  </StyledInputsRowContainer>
                  {bankInfoFormValues.bankAccount && (
                    <StyledInputsRowContainer>
                      <InputField
                        type="text"
                        label="Bank account number"
                        value={bankInfoFormValues.bankAccount}
                        disabled={true}
                      />
                      <InputField
                        type="text"
                        label="Bank routing number"
                        value={bankInfoFormValues.routingNumber}
                        disabled={true}
                      />
                    </StyledInputsRowContainer>
                  )}
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="New bank account number"
                      value={bankInfoFormValues.newBankAccount}
                      onChange={(e) =>
                        handleChange("newBankAccount", e.target.value)
                      }
                    />
                    <InputField
                      type="text"
                      label="New bank routing number"
                      value={bankInfoFormValues.newRoutingNumber}
                      onChange={(e) =>
                        handleChange("newRoutingNumber", e.target.value)
                      }
                    />
                  </StyledInputsRowContainer>
                  {status === UserStatus.INCOMPLETE && (
                    <StyledTosContainer>
                      <SwitchField
                        onChange={(value: boolean) => {
                          setTosAcceptance(value);
                        }}
                        defaultChecked={tosAcceptance || false}
                        label="RE/GIFT use Stripe to make payouts to sellers. The Stripe Recipient Agreement applies to your receipt of such Payouts. To receive payouts from RE/GIFT, you must provide RE/GIFT accurate and complete information about you and your business, and you authorise RE/GIFT to share it and transaction information related to your payout with Stripe."
                      />
                    </StyledTosContainer>
                  )}
                </>
              )}

              <StyledActionButtons>
                <Button width="100%" variant="solid" onClick={updateBankInfo}>
                  Update bank info
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledTabsContent>
      </StyledPageContainer>
    </div>
  );
};
export default MyProfile;
