import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createProduct, getAllProducts } from "@/communication/product";
import PageTitle from "@/components/pageTitle";
import {
  StyledFiltering,
  StyledHeaderButtons,
  StyledModalContent,
  StyledPageHeader,
  StyledPaginationContainer,
  StyledProductCartContainer,
  StyledProductList,
  StyledStatsContainer,
  StyledSwitchContainer,
} from "./style";
import { ProductDto } from "@/global/dtos/product.dto";
import { ReactComponent as ContentIcon } from "@/assets/icons/navbar/content.svg";
import GridViewIcon from "@/assets/icons/gridViewIcon.svg";
import ListViewIcon from "@/assets/icons/listViewIcon.svg";
import NoItems from "@/components/noItems";
import ProductCard from "./productCard";
import Button from "@/components/buttons";
import Modal from "@/components/modal";
import InputField from "@/components/inputs/inputField";
import useToggle from "@/hooks/useToggle";
import {
  StyledForm,
  StyledInputsContainer,
  StyledActionButtons,
} from "./details/style";
import { useTouchField } from "@/hooks/useTouchField";
import SwitchField from "@/components/inputs/switch";
import { capitalizeWords } from "@/functions/capitalLetter";
import useLoading from "@/hooks/useLoading";
import SelectField, { SelectOptionType } from "@/components/select";
import { ProductCategory, ProductCondition } from "@/types/product";
import { getDiscount } from "@/functions/calculateDiscount";
import Search from "@/components/inputs/search";
import Table from "@/components/table";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";
import { getSellers } from "@/communication/user";
import { UserDto, UserStatus } from "@/global/dtos/user.dto";
import { getTotalSales } from "@/communication/payment";
import Pagination from "@/components/pagination";

const Products: FunctionComponent = () => {
  const [products, setProducts] = useState<ProductDto[] | null>(null);
  const [listView, setListView] = useState(false);
  const navigate = useNavigate();
  const modal = useToggle();
  const updateBankInfoModal = useToggle();
  const { showLoading, hideLoading } = useLoading();
  const [searchEvent, setSearchEvent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [sellers, setSellers] = useState<SelectOptionType[]>([]);
  const [category, setCategories] = useState<SelectOptionType[]>([]);
  const [filterCategory, setFilterCategory] = useState<SelectOptionType | null>(
    null
  );
  const [filterSeller, setFilterSeller] = useState<SelectOptionType | null>(
    null
  );
  const [order, setOrder] = useState<SelectOptionType | null>(null);
  const { role, id, status } = useUserStore();
  const [usersList, setUsersList] = useState<UserDto[] | null>(null);
  const [totalSales, setTotalSales] = useState<number | null>(0);
  const [incomingCommission, setIncomingCommission] = useState<number | null>(
    0
  );
  const [payoutsReceived, setPayoutsReceived] = useState<number | null>(0);
  const [totalProductsSold, setTotalProductsSold] = useState<number | null>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const categoriesOptions: SelectOptionType[] = [];
    const categoryArray: ProductCategory[] = Object.values(ProductCategory);
    for (const category of categoryArray) {
      categoriesOptions.push({
        value: category,
        label: category,
      });
    }
    setCategories(categoriesOptions);
    if (role === RoleCodes.ADMIN) {
      getSellers("READY").then((res: any) => {
        setUsersList && setUsersList(res.data);
      });
      const sellersOptions: SelectOptionType[] = [];
      getSellers("READY").then((res: any) => {
        for (const user of res.data) {
          sellersOptions.push({
            value: user?.id,
            label:
              user?.firstName && user?.lastName
                ? user?.firstName + " " + user?.lastName
                : user?.firstName ?? "",
          });
        }
      });
      getSellers("CREATED").then((res: any) => {
        for (const user of res.data) {
          sellersOptions.push({
            value: user?.id,
            label:
              user?.firstName && user?.lastName
                ? user?.firstName + " " + user?.lastName
                : user?.firstName ?? "",
          });
        }
      });
      getSellers("INCOMPLETE").then((res: any) => {
        for (const user of res.data) {
          sellersOptions.push({
            value: user?.id,
            label:
              user?.firstName && user?.lastName
                ? user?.firstName + " " + user?.lastName
                : user?.firstName ?? "",
          });
        }
      });
      setSellers(sellersOptions);
    }
    if (role === RoleCodes.SELLER) {
      getTotalSales(id)
        .then((res: any) => {
          setTotalSales(res.data.totalSales ?? 0);
          setIncomingCommission(res.data.incomingCommission ?? 0);
          setTotalProductsSold(res.data.totalProductsSold ?? 0);
          setPayoutsReceived(res.data.payoutsReceived ?? 0);
          hideLoading();
        })
        .catch((error) => {
          console.log(error);
          hideLoading();
        });
    }
  }, [role]);

  useEffect(() => {
    showLoading();
    getAllProducts(currentPage)
      .then((res: any) => {
        setProducts(res.data.products);
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.currentPage);
        //setFilteredProducts(res.data);
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  }, []);

  useEffect(() => {
    if (status === UserStatus.INCOMPLETE) {
      updateBankInfoModal.open();
    }
  }, [status]);

  useEffect(() => {
    showLoading();
    getAllProducts(
      currentPage,
      searchValue,
      filterCategory?.value,
      filterSeller?.value,
      order?.value
    )
      .then((res: any) => {
        setProducts(res.data.products);
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.currentPage);
        //setFilteredProducts(res.data);
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  }, [searchValue, filterCategory, filterSeller, currentPage, order]);

  const goToTheProduct = (id: string): void => {
    if (
      role === RoleCodes.ADMIN ||
      (role === RoleCodes.SELLER &&
        products?.find((prod) => prod.id === id)?.selfListed)
    ) {
      navigate(`/products/${id}`);
    }
  };

  type TmpProductValues = {
    brand: string | undefined;
    name: string | undefined;
    description: string | undefined;
    blurb: string | undefined;
    price: string | undefined;
    isPublic: boolean;
    selfListed: boolean;
    discount: number | undefined;
    isDiscounted: boolean | undefined;
    size: string | undefined;
    material: string | undefined;
    countryOfOrigin: string | undefined;
    category: ProductCategory;
    condition: ProductCondition;
    sellerId: string | undefined;
    compareAtPrice: string | undefined;
  };

  const [tmpProductValues, setTmpFormValues] = useState<TmpProductValues>({
    brand: undefined,
    name: undefined,
    description: undefined,
    blurb: undefined,
    price: undefined,
    isPublic: false,
    selfListed: false,
    discount: undefined,
    isDiscounted: undefined,
    size: undefined,
    material: undefined,
    countryOfOrigin: undefined,
    category: ProductCategory.CLOTHING,
    condition: ProductCondition.NEVER,
    sellerId: undefined,
    compareAtPrice: undefined,
  });

  const { isFieldTouched, handleFieldTouch } =
    useTouchField<TmpProductValues>();

  const handleChange = (
    fieldName: keyof TmpProductValues,
    value: string
  ): void => {
    handleFieldTouch(fieldName);
    setTmpFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    if (fieldName === "brand")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["brand"]: value }));
    if (fieldName === "name")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["name"]: value }));
    if (fieldName === "description")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["description"]: value,
      }));
    if (fieldName === "blurb")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["blurb"]: value }));
    if (fieldName === "price")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["price"]: value }));
    if (fieldName === "size")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["size"]: value }));
    if (fieldName === "material")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["material"]: value,
      }));
    if (fieldName === "countryOfOrigin")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["countryOfOrigin"]: value,
      }));
    if (fieldName === "sellerId")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["sellerId"]: value,
      }));
    if (fieldName === "compareAtPrice")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["compareAtPrice"]: value,
      }));
  };

  const handleCreate = (): void => {
    showLoading();
    createProduct(tmpProductValues)
      .then(async () => {
        modal.close();
        getAllProducts(currentPage).then((res: any) => {
          setProducts(res.data.products);
        });
        hideLoading();
        toast.success("Product added!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["brand"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["name"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["description"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["price"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["isPublic"]: false,
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["selfListed"]: false,
        }));
        setTmpFormValues({ ...tmpProductValues, discount: 50 });
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["isDiscounted"]: false,
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["size"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["material"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["countryOfOrigin"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["category"]: ProductCategory.CLOTHING,
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["condition"]: ProductCondition.NEVER,
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["compareAtPrice"]: "",
        }));
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    setTmpFormValues((prevValues) => ({
      ...prevValues,
      ["discount"]: getDiscount(
        tmpProductValues.category,
        tmpProductValues.condition
      ),
    }));
  }, [tmpProductValues.category, tmpProductValues.condition]);

  const handleValueChange = (e): void => {
    setSearchEvent(e);
    setSearchValue(e.target.value);
  };

  const handleRowClick = (e): any => {
    // e.stopPropagation();
    goToTheProduct(e.id);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page + 1);
  };

  const handleRedirectToBankInfo = () => {
    navigate(`/my-profile?bank-info=true`);
  };

  return (
    <>
      <Modal isOpen={modal.isOpen} handleClose={modal.close} width="44rem">
        <StyledModalContent>
          <StyledForm>
            {/* <StyledProfilePictureContainer>
              <img src={InfluencerImg}></img>
            </StyledProfilePictureContainer> */}
            <StyledInputsContainer>
              <InputField
                type="text"
                label="BRAND"
                value={tmpProductValues?.brand || ""}
                onChange={(e) =>
                  handleChange("brand", e.target.value.toUpperCase())
                }
                placeholder="BRAND"
              />
              <InputField
                type="text"
                label="Product name"
                value={tmpProductValues?.name || ""}
                onChange={(e) =>
                  handleChange("name", capitalizeWords(e.target.value))
                }
                placeholder="Product name"
              />
              <InputField
                type="textarea"
                label="Product description"
                value={tmpProductValues?.description || ""}
                onChange={(e) => handleChange("description", e.target.value)}
                placeholder="Product description"
              />
              {/* <InputField
                type="text"
                label="Please describe why you love this product"
                value={tmpProductValues?.blurb || ""}
                onChange={(e) => handleChange("blurb", e.target.value)}
                placeholder="Please describe why you love this product"
              /> */}
              <InputField
                type="text"
                label="Listed price"
                value={tmpProductValues?.price || ""}
                onChange={(e) => handleChange("price", e.target.value)}
                placeholder="Listed price"
              />
              <InputField
                type="text"
                label="Compare at price"
                value={tmpProductValues?.compareAtPrice || ""}
                onChange={(e) => handleChange("compareAtPrice", e.target.value)}
                placeholder="Compare at price"
              />
              <StyledSwitchContainer>
                <SwitchField
                  onChange={(value: boolean) => {
                    setTmpFormValues((prevValues) => ({
                      ...prevValues,
                      ["isPublic"]: value,
                    }));
                  }}
                  defaultChecked={tmpProductValues.isPublic || false}
                  label="Product is Public"
                />
                {/* <SwitchField
                  onChange={(value: boolean) => {
                    setTmpFormValues((prevValues) => ({
                      ...prevValues,
                      ["selfListed"]: value,
                    }));
                  }}
                  defaultChecked={tmpProductValues.selfListed || false}
                  label="Product is Self-Listed"
                /> */}
                <SwitchField
                  onChange={(value: boolean) => {
                    setTmpFormValues((prevValues) => ({
                      ...prevValues,
                      ["isDiscounted"]: value,
                    }));
                  }}
                  defaultChecked={tmpProductValues.isDiscounted || false}
                  label="Product is Discounted"
                />
              </StyledSwitchContainer>

              {/* <InputField
                type="text"
                label="Size"
                value={tmpProductValues?.size || ""}
                onChange={(e) => handleChange("size", e.target.value)}
                placeholder="Size"
              />
              <InputField
                type="text"
                label="Material Content"
                value={tmpProductValues?.material || ""}
                onChange={(e) => handleChange("material", e.target.value)}
                placeholder="Material Content"
              />
              <InputField
                type="text"
                label="Country of Origin"
                value={tmpProductValues?.countryOfOrigin || ""}
                onChange={(e) =>
                  handleChange("countryOfOrigin", e.target.value)
                }
                placeholder="Country of Origin"
              /> */}
              <SelectField
                label="Category"
                options={Object.keys(ProductCategory).map((category) => {
                  return { value: category, label: category };
                })}
                onChange={(e) => {
                  setTmpFormValues({
                    ...tmpProductValues,
                    category: e.value,
                  });
                }}
                placeholder={tmpProductValues.category}
              />
              <SelectField
                label="Condition"
                options={Object.keys(ProductCondition).map((condition) => {
                  return { value: condition, label: condition };
                })}
                onChange={(e) => {
                  setTmpFormValues({
                    ...tmpProductValues,
                    condition: e.value,
                  });
                }}
                placeholder={tmpProductValues.condition}
              />
              <SelectField
                label="Seller"
                options={usersList?.map((seller) => {
                  return {
                    value: seller.id,
                    label: `${seller.firstName} ${seller.lastName}`,
                  };
                })}
                onChange={(e) => {
                  handleChange("sellerId", e.value);
                }}
              />
              <InputField
                type="number"
                label="Discount"
                value={tmpProductValues?.discount}
                onChange={(e) => {
                  setTmpFormValues({
                    ...tmpProductValues,
                    discount: parseInt(e.target.value),
                  });
                }}
                placeholder={getDiscount(
                  tmpProductValues.category ?? ProductCategory.CLOTHING,
                  tmpProductValues.condition ?? ProductCondition.NEVER
                ).toString()}
                errorMessage={
                  tmpProductValues?.discount &&
                  (tmpProductValues?.discount < 25 ||
                    tmpProductValues?.discount > 80)
                    ? "Procenteges must be in range of 50 an 80 procent"
                    : ""
                }
              />
              <StyledActionButtons>
                <Button width="152px" variant="outlined" onClick={modal.close}>
                  CANCEL
                </Button>
                <Button width="152px" variant="solid" onClick={handleCreate}>
                  Add
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledModalContent>
      </Modal>

      <Modal
        isOpen={updateBankInfoModal.isOpen}
        handleClose={updateBankInfoModal.close}
        width="25rem"
      >
        <StyledModalContent>
          <StyledInputsContainer>
            <div style={{ fontSize: "large" }}>
              Please include your bank info to complete account set up
              <br />
              <span style={{ color: "#6C757D", fontSize: "small" }}>
                Note: RE/GIFT uses a secure payment processor and does not store
                your bank details
              </span>
            </div>
            <StyledActionButtons>
              <Button
                width="152px"
                variant="solid"
                onClick={handleRedirectToBankInfo}
              >
                Update now
              </Button>
              <Button
                width="152px"
                variant="outlined"
                onClick={updateBankInfoModal.close}
              >
                DISMISS
              </Button>
            </StyledActionButtons>
          </StyledInputsContainer>
        </StyledModalContent>
      </Modal>
      <div className="page">
        <StyledPageHeader>
          <PageTitle title="Products page" icon={<ContentIcon />} />
          <StyledFiltering>
            <Search
              placeholder="Search"
              onChange={handleValueChange}
              className="search"
            />
            {role === RoleCodes.ADMIN ? (
              <SelectField
                options={sellers}
                onChange={(selectedOption: SelectOptionType) =>
                  setFilterSeller(selectedOption)
                }
                isClearable={true}
                placeholder="Find by seller"
              />
            ) : null}
            <SelectField
              options={category}
              onChange={(selectedOption: SelectOptionType) =>
                setFilterCategory(selectedOption)
              }
              isClearable={true}
              placeholder="Find by category"
            />
            <SelectField
              options={[
                {
                  value: "alphabeticalAsc",
                  label: "A-Z",
                },
                {
                  value: "alphabeticalDsc",
                  label: "Z-A",
                },
                {
                  value: "priceAsc",
                  label: "Price, low to high",
                },
                {
                  value: "priceDsc",
                  label: "Price, high to low",
                },
                {
                  value: "dateAsc",
                  label: "Date, new to old",
                },
                {
                  value: "dateDsc",
                  label: "Date, old to new",
                },
              ]}
              onChange={(selectedOption: SelectOptionType) =>
                setOrder(selectedOption)
              }
              isClearable={true}
              placeholder="Sort by"
            />
          </StyledFiltering>

          <StyledHeaderButtons>
            <div>
              <Button
                width="50px"
                variant="outlined"
                onClick={() => {
                  setListView(true);
                }}
              >
                <img src={ListViewIcon}></img>
              </Button>
              <Button
                width="50px"
                variant="outlined"
                onClick={() => {
                  setListView(false);
                }}
              >
                <img src={GridViewIcon}></img>
              </Button>
            </div>
            {role === RoleCodes.ADMIN && (
              <Button width="152px" variant="solid" onClick={modal.open}>
                Add new
              </Button>
            )}
          </StyledHeaderButtons>
        </StyledPageHeader>
        {role === RoleCodes.SELLER ? (
          <StyledStatsContainer>
            <h4>{`Your total sales: $${totalSales}`}</h4>
            <h4>{`Total products sold: ${totalProductsSold}`}</h4>
            <h4>{`Payouts you've received: $${payoutsReceived}`}</h4>
            {/* <h4>{`Incoming funds: $${incomingCommission}`}</h4> */}
          </StyledStatsContainer>
        ) : null}
        {products ? (
          listView ? (
            <>
              <Table
                items={products?.map((product) => ({
                  id: product.id,
                  brand: product.brand.name,
                  name: product.name,
                  price: `$${product.price}`,
                  expectedCommission: `$${product.expectedCommission}`,
                }))}
                columns={[
                  { accessorKey: "brand", header: "Brand" },
                  { accessorKey: "name", header: "Name" },
                  { accessorKey: "price", header: "Listed price" },
                  {
                    accessorKey: "expectedCommission",
                    header: "Potential commission",
                  },
                ]}
                handleRowClick={(e) => handleRowClick(e)}
                pagination={{
                  pageCount: totalPages,
                  initialPage: currentPage,
                  onPageChange: handlePageChange,
                }}
              />
            </>
          ) : (
            <>
              <StyledProductList>
                {products.length !== 0 ? (
                  products.map((product) => {
                    return (
                      <StyledProductCartContainer
                        key={product.id}
                        onClick={() => goToTheProduct(product.id)}
                      >
                        <ProductCard product={product} />
                      </StyledProductCartContainer>
                    );
                  })
                ) : (
                  <NoItems
                    headline="No products"
                    description={`You don't have any products yet`}
                  />
                )}
              </StyledProductList>
              <StyledPaginationContainer>
                <Pagination
                  pageCount={totalPages}
                  initialPage={currentPage}
                  arrows
                  onPageChange={handlePageChange}
                />
              </StyledPaginationContainer>
            </>
          )
        ) : (
          <NoItems
            headline="No products"
            description={`You don't have any products yet`}
          />
        )}
      </div>
    </>
  );
};
export default Products;
